
import Vue from 'vue';
import Component from 'vue-class-component'

import VideoUpload from '@/components/dialogs/VideoUpload.vue';
import VideoCard from '@/components/cards/VideoCard.vue';
import SearchCard from '@/components/cards/SearchCard.vue';
import NotFoundCard from '@/components/cards/NotFoundCard.vue';

import { categories } from '@/constants';
import { Video } from '@/entities/videos/Video';

@Component({
  components: {
    VideoUpload,
    VideoCard,
    SearchCard,
    NotFoundCard
  }
})
export default class VideosList extends Vue{
  videos: Video[] = [];

  selectedCategories: string[] = [];
  categories = categories;

  search = "";
  dialog = false;

  mounted() {
    this.fetchVideos();
  }

  get filtered() {
    return this.$helpers.filterVideos(this.videos, this.search);
  }

  async fetchVideos() {
    this.$videos.list().then(videos => {
      this.videos = videos;
    });
  }
}
